import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import EllipsisMenu       from 'app/components/common/ellipsis-menu';
import FancyImage         from 'app/components/common/fancy-image';
import Icon               from 'app/components/common/icon';
import Modal              from 'app/components/common/modal';
import MultiImageInput    from 'app/components/common/multi-image-input';
import StandardInput      from 'app/components/common/standard-input';
import ModalImages        from 'app/components/social/modal-post-images';
import ModalUrls          from 'app/components/social/modal-post-urls';
import UrlPreview         from 'app/components/social/url-preview';
import {
  USER_DEFAULT_AVATAR_URL,
}                         from 'app/constants';
import Duck               from 'app/ducks/modal-social-post-form';
import cdn                from 'app/helpers/cdn';
import helpers            from 'app/helpers/social';
import format             from 'app/helpers/format';
import AuthSlx            from 'app/selectors/auth';

const initialState = {
  showImgModal: false,
  showUrlModal: false,
  isUploading: false,
  carouselIndex: 0,
  hideMii: false,
};

class ModalSocialPostForm extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {...initialState};

    this.refBody = React.createRef();
    this.refMii = React.createRef();

    this.onClose = this.onClose.bind(this);
    this.onChangeBody = this.onChangeBody.bind(this);
    this.onAddImage = this.onAddImage.bind(this);
    this.onClickImageModal = this.onClickImageModal.bind(this);
    this.onCloseImageModal = this.onCloseImageModal.bind(this);
    this.onClickDeleteAllImages = this.onClickDeleteAllImages.bind(this);
    this.onClickAddMore = this.onClickAddMore.bind(this);
    this.onChangeIsUploading = this.onChangeIsUploading.bind(this);
    this.onHideMii = this.onHideMii.bind(this);
    this.onClickUrlModal = this.onClickUrlModal.bind(this);
    this.onCloseUrlModal = this.onCloseUrlModal.bind(this);
    this.onClickRemoveUrlPreview = this.onClickRemoveUrlPreview.bind(this);
    this.onClickPost = this.onClickPost.bind(this);
  }

  componentDidUpdate(prevProps) {
    this.fixHeight();
    const didOpen = !prevProps.isOpen && this.props.isOpen;
    if (didOpen) this.onOpen();
  }

  get largeText() {
    return helpers.useLargeText(this.props);
  }

  get canSubmit() {
    if (!this.props.canSubmit) return false;
    if (this.state.isUploading) return false;
    // TODO: check url fetching status
    return true;
  }

  get carouselIndex() {
    const {carouselIndex} = this.state;
    const {imgPaths} = this.props;
    if (carouselIndex > ((imgPaths?.length || 0) - 1)) return 0;
    return carouselIndex;
  }

  onClose() {
    this.props.close();
  }

  onOpen() {
    const textEl = this.refBody.current;
    if (textEl) textEl.focus();
    this.setState(initialState);
  }

  onChangeBody(event) {
    const body = event.target.value;
    this.props.setBody(body);
  }

  onClickImageModal(event) {
    event.preventDefault();
    this.setState({showImgModal: true});
  }
  onCloseImageModal() {
    this.setState({showImgModal: false, carouselIndex: 0});
  }

  onClickDeleteAllImages(event) {
    event.preventDefault();
    this.props.imgDeleteAll();
  }

  onClickAddMore(event) {
    event.preventDefault();
    this.refMii.current.onClickOpen();
  }

  onChangeIsUploading(isUploading) {
    this.setState({isUploading});
  }

  onHideMii() {
    this.setState({hideMii: true});
  }

  onClickUrlModal(event) {
    event && event.preventDefault();
    this.setState({showUrlModal: true});
  }
  onCloseUrlModal() {
    this.setState({showUrlModal: false});
  }
  onClickRemoveUrlPreview(event) {
    event.preventDefault();
    this.props.urlSetPreview(null);
  }

  onAddImage(imgPath) {
    this.props.imgAdd(imgPath);
  }

  onClickCarImg(carouselIndex) {
    this.setState({carouselIndex});
  }

  onClickPost() {
    this.props.submit();
  }

  fixHeight() {
    const el = this.refBody.current;
    if (!el) return;
    el.style.height = '0px';
    el.style.height = `${el.scrollHeight}px`;
  }

  renderMainImg() {
    const {imgPaths} = this.props;
    const {isUploading} = this.state;
    const mainImgPath = imgPaths[this.carouselIndex];
    if (!mainImgPath) return null;
    const showManageOpt = imgPaths.length > 1;
    return (
      <div className="spost-form-main-img">
        <FancyImage imgPath={mainImgPath} className="spost-form-main-img-fancy" />
        <div className="spost-form-main-img-menu">
          <EllipsisMenu left>
            {showManageOpt && (
              <a href="#" onClick={this.onClickImageModal}><Icon.LayoutDashboard /> Manage Photos</a>
            )}
            {!isUploading && (
              <a href="#" onClick={this.onClickAddMore}><Icon.ImageFileAdd /> Add More</a>
            )}
            <a href="#" onClick={this.onClickDeleteAllImages}><Icon.Bin /> {(imgPaths.length > 1) ? 'Delete All' : 'Delete'}</a>
          </EllipsisMenu>
        </div>
      </div>
    );
  }

  renderCarousel() {
    const {imgPaths} = this.props;
    if (imgPaths.length <= 1) return null;

    return (
      <div className="spost-form-car">
        {imgPaths.map((imgPath, i) => {
          const isActive = i === this.carouselIndex;
          const activeClass = isActive ? 'active' : '';
          return (
            <div className={`spost-form-car-img-con ${activeClass}`} onClick={this.onClickCarImg.bind(this, i)} key={i}>
              <img className="spost-form-car-img" src={cdn.imgUrl(imgPath, {height: 120})} />
            </div>
          );
        })}
      </div>
    );
  }

  renderUrlPreview() {
    const {previewUrlObj, urlObjs} = this.props;
    if (!previewUrlObj) return null;
    const showChangeOpt = urlObjs?.length > 1;
    return (
      <div className="spost-form-url">
        <UrlPreview urlObj={previewUrlObj} className="spost-form-url-preview" noClick />
        <div className="spost-form-url-menu">
          <EllipsisMenu left>
            {showChangeOpt && (
              <a href="#" onClick={this.onClickUrlModal}><Icon.DataTransferHorizontal /> Change Preview</a>
            )}
            <a href="#" onClick={this.onClickRemoveUrlPreview}><Icon.Bin /> Remove Preview</a>
          </EllipsisMenu>
        </div>
      </div>
    );
  }

  render() {
    const {isNew, isOpen, isSubmitting, body, imgPaths, currentUser, employee, urlObjs, previewUrlObj, urlsLoading} = this.props;
    const {showImgModal, showUrlModal, isUploading, hideMii} = this.state;
    if (!isOpen || !employee) return null;
    const prompt = `What would you like to share, ${employee.firstName}?`;
    const avatarUrl = currentUser.avatarUrl
      ? cdn.imgUrl(currentUser.avatarUrl, {width: 120})
      : USER_DEFAULT_AVATAR_URL;
    const btnDisabled = (() => {
      if (!this.canSubmit) return true;
      if (isUploading) return true;
      if (isSubmitting) return true;
      if (urlsLoading) return true;
      return false;
    })();
    const btnLabel = isNew
      ? (isSubmitting ? 'Posting...'  : 'Post')
      : (isSubmitting ? 'Updating...' : 'Update Post');
    const showUrlPreviewBtn = !!urlObjs.length && !previewUrlObj && !imgPaths.length;
    const showAddImgBtn = !!(!imgPaths.length && hideMii);

    return (
      <Modal onClose={this.onClose} className="bform spost-form">
        <div className="spost-form-user">
          <img className="spost-form-user-avatar" src={avatarUrl} />
          <span className="spost-form-user-name">{`${employee.firstName} ${employee.lastName}`}</span>
        </div>

        <textarea rows="1" ref={this.refBody} className={`spost-form-text ${this.largeText ? 'sz-l' : 'sz-s'}`} placeholder={prompt} value={body || ''} onChange={this.onChangeBody} />

        {this.renderMainImg()}
        {this.renderCarousel()}
        {this.renderUrlPreview()}

        <MultiImageInput onAdd={this.onAddImage} className="spost-form-mii" hideWhenDone={!!imgPaths.length || hideMii} onChangeIsUploading={this.onChangeIsUploading} ref={this.refMii} onHide={this.onHideMii} />

        {/* <pre>{JSON.stringify(_.pick(this.props, ['canSubmit', 'saveAttrs']), null, 4)}</pre> */}

        {showImgModal && (
          <ModalImages onClose={this.onCloseImageModal} />
        )}
        {showUrlModal && (
          <ModalUrls onClose={this.onCloseUrlModal} />
        )}

        <div className="bform-actions">
          <div className="bform-actions-left btn-row">
            {showUrlPreviewBtn && (
              <button className="btn secondary icon" onClick={this.onClickUrlModal}><Icon.DataTransferHorizontal /> Preview</button>
            )}
            {showAddImgBtn && (
              <button className="btn secondary icon" onClick={this.onClickAddMore}><Icon.ImageFileAdd /> Add Photos</button>
            )}
          </div>
          <div className="bform-actions-right">
            {!isNew && (
              <button className="btn secondary" onClick={this.onClose} disabled={isSubmitting}>Cancel</button>
            )}
            <button className="btn icon" onClick={this.onClickPost} disabled={btnDisabled}><Icon.SendEmail /> {btnLabel}</button>
          </div>
        </div>
      </Modal>
    );
  }

}

ModalSocialPostForm.propTypes = {
};

ModalSocialPostForm.defaultProps = {

};

const stateToProps = (state) => ({
  currentUser: AuthSlx.currentUser(state),
  employee: AuthSlx.currentUser(state)?.employment?.employee,

  isNew: Duck.Slx.isNew(state),
  isOpen: Duck.Slx.isOpen(state),
  isSubmitting: Duck.Slx.isSubmitting(state),
  // post: Duck.Slx.post(state),
  body: Duck.Slx.body(state),
  imgPaths: Duck.Slx.imgPaths(state),
  urlObjs: Duck.Slx.urlObjs(state),
  urlsLoading: Duck.Slx.urlsLoading(state),
  previewUrlObj: Duck.Slx.previewUrlObj(state),
  saveAttrs: Duck.Slx.saveAttrs(state),
  canSubmit: Duck.Slx.canSubmit(state),
});

const dispatchToProps = (dispatch) => ({
  close: () => dispatch(Duck.Ax.close()),
  setBody: (body) => dispatch(Duck.Ax.setBody(body)),
  imgAdd: (imgPath) => dispatch(Duck.Ax.imgAdd(imgPath)),
  imgDeleteAll: () => dispatch(Duck.Ax.imgDeleteAll()),
  urlSetPreview: (url) => dispatch(Duck.Ax.urlSetPreview(url)),
  submit: () => dispatch(Duck.Ax.submit()),
});

export default connect(stateToProps, dispatchToProps)(ModalSocialPostForm);
