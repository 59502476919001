import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import Icon          from 'app/components/common/icon';
import Masonry       from 'app/components/social/masonry';
import {
  SocialFeedTypes as FeedTypes,
  EmployeeRoles,
}                    from 'app/constants';
import ModalPostDuck from 'app/ducks/modal-social-post-form';
import Duck          from 'app/ducks/social';
import cdn           from 'app/helpers/cdn';
import AuthSlx       from 'app/selectors/auth';

class SocialFeed extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      ids: null,
      moreCount: null,
      isLoading: false,
      hasPinChanges: false,
    };

    this.onWantsCreate = this.onWantsCreate.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onClickLoadMore = this.onClickLoadMore.bind(this);
    this.onClickRefresh = this.onClickRefresh.bind(this);
    this.onChangePin = this.onChangePin.bind(this);
  }

  componentDidMount() {
    if (this.props.loadInitial) this.onClickLoadMore();
  }

  get moreCount() {
    return this.state.moreCount != null
      ? this.state.moreCount
      : this.props.initialMoreCount;
  }

  get lastId() {
    return _.last(this.getRealIds());
  }

  get showFirstPrompt() {
    return !this.getRealIds().length;
  }

  get currentEmployee() {
    return this.props.currentUser?.employment?.employee;
  }

  get isAdmin() {
    if (!this.currentEmployee) return false;
    return this.currentEmployee.role === EmployeeRoles.ADMIN;
  }

  get canPin() {
    return this.isAdmin;
  }

  get canPost() {
    if (this.props.editMode) return false;
    if (this.isAdmin) return true;
    return this.props.type !== FeedTypes.COMPANY;
  }

  getRealIds(stateIds=this.state.ids) {
    return stateIds || this.props.initialIds || [];
  }

  getDisplayIds(stateIds=this.state.ids) {
    const realIds = this.getRealIds(stateIds);
    const promptIds = this.canPost ? ['prompt'] : [];

    // edit mode - just show skeletons with no prompt
    if (this.props.editMode) {
      return ['variant-1-a', 'variant-4-a', 'variant-3-a', 'variant-2-a', 'variant-2-b', 'variant-4-b'];
    }
    // empty state
    if (!realIds.length) {
      return [...promptIds, 'variant-1-a', 'variant-2-a', 'variant-3-a', 'variant-4-a']
    }
    // normal state
    return [...promptIds, ...realIds];
  }

  async onClickLoadMore(event, isRefresh=false) {
    const beforeListingId = isRefresh ? null : this.lastId;
    this.setState({isLoading: true});
    const {moreCount, socialPostListings: listings} = await this.props.search({beforeListingId});
    const ids = listings.map(l => l.id);
    this.setState((prevState) => {
      const prevIds = this.getRealIds(prevState.ids);
      return {
        isLoading: false,
        ids: isRefresh ? [...ids] : [...prevIds, ...ids],
        moreCount,
        hasPinChanges: false,
      }
    });
  }

  onClickRefresh() {
    this.onClickLoadMore(undefined, true);
  }

  onWantsCreate() {
    const {type: feedType, id: feedId, openCreateModal, editMode} = this.props;
    if (editMode) return;
    openCreateModal({feedType, feedId, onCreate: this.onCreate});
  }

  onChangePin() {
    this.setState({hasPinChanges: true});
  }

  onCreate(listing) {
    this.setState((prevState) => {
      return {
        ids: [listing.id, ...this.getRealIds(prevState.ids)],
      };
    });
    setTimeout(() => {
      const cardEl = document.querySelector(`.smasonry-cols .spost-card[data-id="${listing.id}"]`);
      if (cardEl) {
        const offset = -124;
        const scrollTo = window.scrollY + cardEl.getBoundingClientRect().top + offset;
        window.scrollTo({top: scrollTo, behavior: 'smooth'});
      }
    }, 200);
  }

  render() {
    const {className, title} = this.props;
    const {hasPinChanges} = this.state;
    const isExhausted = !this.moreCount;
    const showControls = !!(this.moreCount || hasPinChanges || this.canPost);

    return (
      <div className={`sfeed ${isExhausted ? 'exhausted' : 'has-more'} ${className}`}>
        {this.showFirstPrompt && (
          <p className="sfeed-first-prompt">{`Share photos or messages on ${title || 'the feed'}:`}</p>
        )}
        <div className={`sfeed-masonry`}>
          <Masonry listingIds={this.getDisplayIds()} onWantsCreate={this.canPost ? this.onWantsCreate : undefined} onChangePin={this.canPin ? this.onChangePin : null} />
          {showControls && (
            <div className="sfeed-masonry-controls">
              {hasPinChanges && (
                <button onClick={this.onClickRefresh} className="btn secondary small">Refresh Feed</button>
              )}
              {!!(this.moreCount && !hasPinChanges) && (
                <button onClick={this.onClickLoadMore} className="btn secondary small">Load More</button>
              )}
              {this.canPost && (
                <button className="btn special pink icon small" onClick={this.onWantsCreate}><Icon.SendEmail />Create a Post</button>
              )}
            </div>
          )}
        </div>
        {/* {this.canPost && ( */}
        {/*   <div className="sfeed-controls"> */}
        {/*     <button className="btn special pink icon" onClick={this.onWantsCreate}><Icon.SendEmail />Create a Post</button> */}
        {/*   </div> */}
        {/* )} */}
      </div>
    );
  }

}

SocialFeed.propTypes = {
  type: PropTypes.oneOf(Object.values(FeedTypes)).isRequired,
  id: PropTypes.string.isRequired,
  initialIds: PropTypes.arrayOf(PropTypes.string),
  initialMoreCount: PropTypes.number,
  title: PropTypes.string.isRequired,
  imgPath: PropTypes.string,
  loadMoreLimit: PropTypes.number,
  className: PropTypes.string,
  editMode: PropTypes.bool,
  loadInitial: PropTypes.bool,
};

SocialFeed.defaultProps = {
  initialIds: [],
  initialMoreCount: 0,
  loadMoreLimit: 30,
  className: '',
  editMode: false,
  loadInitial: false,
};

const stateToProps = (state, ownProps) => ({
  currentUser: AuthSlx.currentUser(state),
});

const dispatchToProps = (dispatch, ownProps) => ({
  openCreateModal: (params) => dispatch(ModalPostDuck.Ax.open(params)),
  search: (params={}) => {
    const {type: feedType, id: feedId} = ownProps;
    const limit = ownProps.loadMoreLimit || SocialFeed.defaultProps.loadMoreLimit;
    return dispatch(Duck.Ax.listingsSearch({feedType, feedId, limit, ...params}))
  },
});

export default connect(stateToProps, dispatchToProps)(SocialFeed);
